<template>
  <div>
    <top-navbar
      :topNavbarType="['back-button-target', 'app-name']"
      :topNavbarData="{ title: 'Order Summary', backTarget: '/home' }"
    ></top-navbar>
    <div class="container">
      <div
        v-if="billDetails.status === 'ORDER_WAITING'"
        class="order-status-container"
      >
        <div class="image-container">
          <img src="/images/preparation-started.png" alt="Image" />
        </div>
        <div class="status">
          Waiting for Order to be accepted by Restaurant!
        </div>
      </div>
      <div
        v-if="
          billDetails.status === 'ORDER_ACCEPTED' &&
          !(
            orderData?.deliveryDetail?.status === 'ended' ||
            orderData?.deliveryDetail?.status === 'completed'
          )
        "
        class="order-status-container"
      >
        <div class="image-container">
          <img src="/images/preparation-started.png" alt="Image" />
        </div>
        <div class="status">
          Your Order has been accepted by the Restaurant!
        </div>
      </div>
      <div
        v-if="billDetails.status === 'ORDER_REJECTED'"
        class="order-status-container"
      >
        <div class="image-container">
          <img src="/images/preparation-started.png" alt="Image" />
        </div>
        <div class="status">
          We are Sorry ! <br />Your Order has been rejected by the Restaurant!
        </div>
      </div>
      <div
        v-if="
          billDetails.status === 'PREPARATION_STARTED' &&
          !(
            orderData?.deliveryDetail?.status === 'ended' ||
            orderData?.deliveryDetail?.status === 'completed'
          )
        "
        class="order-status-container"
      >
        <div class="image-container">
          <img src="/images/preparation-started.png" alt="Image" />
        </div>
        <div class="status">Your Food is getting Prepared!</div>
      </div>
      <div
        v-if="
          billDetails.status === 'PREPARATION_DONE' &&
          !(
            orderData?.deliveryDetail?.status === 'ended' ||
            orderData?.deliveryDetail?.status === 'completed'
          )
        "
        class="order-status-container"
      >
        <div class="image-container">
          <img src="/images/preparation-started.png" alt="Image" />
        </div>
        <div class="status">Your Food is Prepared!</div>
      </div>

      <div v-if="billDetails.status === 'ORDER_REJECTED'">
        <div v-if="billDetails?.refundData?.refund_status === 'PENDING'">
          <div>Refund has been initiated</div>
        </div>
        <div v-if="billDetails?.refundData?.refund_status === 'SUCCESS'">
          <div>Refund Successfull!</div>
        </div>
      </div>

      <div
        v-if="
          billDetails.status === 'DISPATCHED' &&
          !(
            orderData?.deliveryDetail?.status === 'ended' ||
            orderData?.deliveryDetail?.status === 'completed'
          )
        "
        class="order-status-container"
      >
        <div class="image-container">
          <img src="/images/preparation-started.png" alt="Image" />
        </div>
        <div class="status">Our delivery partner is out for delivery!</div>
      </div>
      <div
        v-if="
          orderData?.deliveryDetail?.status === 'ended' ||
          orderData?.deliveryDetail?.status === 'completed'
        "
        class="order-status-container"
      >
        <div class="image-container">
          <img src="/images/preparation-started.png" alt="Image" />
        </div>
        <div class="status">Your food has been delivered!</div>
      </div>
      <div
        v-if="
          orderData?.deliveryDetail?.status === 'ended' ||
          orderData?.deliveryDetail?.status === 'completed'
        "
      >
        <rating-component
          :reviewDetail="reviewDetail"
          :hideSubmitButton="hideSubmitButton"
          @submitRatingButton="submitRatingButton"
        />
      </div>
      <div
        v-if="
          !(
            orderData?.deliveryDetail?.status === 'ended' ||
            orderData?.deliveryDetail?.status === 'completed'
          )
        "
        class="delivery-status"
      >
        <div v-if="showMapOrNot(orderData)" class="map-container">
          <div class="map-container-div">
            <div id="map"></div>
          </div>
          <div v-if="orderData.deliveryDetail" class="partner-panel">
            <div class="display-flex">
              {{
                orderData.deliveryDetail.partner_info &&
                orderData.deliveryDetail.partner_info?.name
              }}
              <image-button
                :src="'/images/call.png'"
                :width="'20px'"
                alt=""
                :color="'white'"
                @click="callDeliveryGuy()"
              />
            </div>
          </div>
        </div>

        <div v-else-if="orderData?.deliveryDetail?.status === 'ended'">
          <div>Your Order has been delivered</div>
        </div>
        <div v-else-if="orderData?.deliveryDetail?.status === 'completed'">
          <div>Your Order has been delivered</div>
        </div>
        <div v-else-if="orderData?.deliveryDetail?.status === 'open'">
          <div>Delivery agent is yet to be assigned</div>
        </div>
      </div>

      <div class="restaurant-detail-container">
        <div>
          <p>{{ restaurantDetails.name }}</p>
          <p>{{ restaurantDetails.address }}</p>
        </div>
        <image-button
          :src="'/images/call.png'"
          :width="'20px'"
          alt=""
          :color="'white'"
          @click="callRestaurantGuy()"
        />
      </div>

      <div
        v-for="order in orderList"
        :key="order._id"
        :class="['check-in-cards', order.active ? 'check-in-active' : '']"
      >
        <div class="menu-count">
          <p>{{ order.itemName }}</p>
          <div class="display-flex">
            <p>{{ `${order.count}&nbsp; x &nbsp;` }}</p>
            <div v-if="order.discountPrice" class="display-flex">
              <p>₹ {{ order.discountPrice }}</p>
              &nbsp;&nbsp; &nbsp;
              <p>
                <s>₹ {{ order.itemPrice }}</s>
              </p>
            </div>
            <div v-else class="display-flex-vertical">
              <p>₹ {{ order.itemPrice }}</p>
            </div>
          </div>
        </div>
        <div
          v-for="customization in order.customization"
          :key="customization.name"
          class=""
        >
          <div class="display-flex">
            <p>{{ customization.localisedName }}</p>
            <p>₹ {{ customization.price }}</p>
          </div>
        </div>
      </div>
      <div :style="{ marginBottom: '10px' }">
        {{ getDateTime(orderData?.createdOn) }}
      </div>
      <div>
        <div
          v-if="this.billDetails.totalFoodBill"
          class="bill-detail-container"
        >
          <div v-if="this.billDetails.couponDiscountAmount">
            <div class="display-flex discount-strip">
              <p class="bill-label">Coupon Code</p>
              <p>
                {{ this.billDetails.couponCode }}
              </p>
            </div>
          </div>
          <div>
            <div class="display-flex">
              <p class="bill-label">Total</p>
              <div
                v-if="
                  this.billDetails.discountedFoodBill !==
                  this.billDetails.totalFoodBill
                "
                :style="{ display: 'flex' }"
              >
                <p>₹ {{ this.billDetails.discountedFoodBill }}</p>
                &nbsp;
                <p>
                  <s>₹ {{ this.billDetails.totalFoodBill }}</s>
                </p>
              </div>
              <div v-else>
                <p>₹ {{ this.billDetails.totalFoodBill }}</p>
              </div>
            </div>
          </div>
          <div v-if="this.billDetails.deliveryBill" class="display-flex">
            <p class="bill-label">Delivery Cost</p>
            <p>₹ {{ this.billDetails.deliveryBill }}</p>
          </div>
          <div v-if="this.billDetails.couponDiscountAmount">
            <div class="display-flex discount-strip">
              <p class="bill-label">Discount</p>
              <p>
                - ₹
                {{ this.billDetails.couponDiscountAmount }}
              </p>
            </div>
          </div>
          <div class="display-flex">
            <p class="bill-label">Tax Amount</p>
            <p>₹ {{ this.billDetails.taxAmount }}</p>
          </div>
          <div class="display-flex">
            <p class="bill-label">Amount Paid</p>
            <p>₹ {{ this.billDetails.totalBillToPay }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div>
        <button-comp
          :value="'Reorder'"
          :height="'40px'"
          :width="'100%'"
          :color="'crimson'"
          @click="gotoRestaurantMenu"
        ></button-comp>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import TopNavbar from "../../components/partials/TopNavbar.vue";
import RatingComponent from "../../components/RatingComponent.vue";
// import { GoogleMap } from 'vue3-google-map';
// import { GmapMap } from 'vue2-google-maps';
// import { onMounted } from "vue";

export default {
  components: { TopNavbar, RatingComponent },
  data() {
    return {
      houseImage:
        "https://tumyto-prod-bucket.s3.ap-south-1.amazonaws.com/house.png",
      riderImage:
        "https://tumyto-prod-bucket.s3.ap-south-1.amazonaws.com/rider.png",
      storeImage:
        "https://tumyto-prod-bucket.s3.ap-south-1.amazonaws.com/store.png",
      reviewDetail: null,
      hideSubmitButton: false,
      counter: 0,
      orderList: [],
      orderData: {},
      billDetails: {},
      restaurantDetails: {},
      origin: "",
      directionsOptions: {
        provideRouteAlternatives: false,
        travelMode: "DRIVING",
        drivingOptions: {
          departureTime: new Date(/* now, or future date */),
          trafficModel: "pessimistic",
        },
      },
      destination: "",
      center: { lat: 25.774, lng: -80.19 },
      startLocation: {},
      endLocation: {},
      startImage: this.riderImage,
      endImage: "",
    };
  },
  async created() {
    const self = this;
    window.onpopstate = function () {
      self.$router.push("my-orders");
    };

    await this.getOrderDetails();
    this.reviewDetail = await this.getApiCall("/delivery/getReviewDetail", {
      billId: this.billDetails._id,
    });
    if (this.reviewDetail?.rating) {
      this.hideSubmitButton = false;
    }
  },
  computed: {
    getCenter() {
      return this.center;
    },
  },
  methods: {
    async submitRatingButton(rating, review) {
      if (rating) {
        let result = await this.postApiCall("/delivery/submitRatingReview", {
          billId: this.billDetails._id,
          restaurantId: this.billDetails.restaurantId,
          rating,
          review,
        });
        if (result === "REVIEW_ALREADY_SUBMITTED") {
          this.errorAlert("Review already submitted once!");
        } else {
          this.infoAlert("Thanks for your reviews");
        }
      } else {
        this.errorAlert("Please add rating");
      }
    },
    showMapOrNot(orderData) {
      let y =
        orderData?.deliveryDetail?.partner_info?.name &&
        orderData?.deliveryDetail?.status !== "ended";
      return y;
    },
    async calculateAndDisplayRoute(directionsService, directionsRenderer, map) {
      directionsService
        .route({
          ...this.directionsOptions,
          origin: this.startLocation,
          destination: this.endLocation,
        })
        .then((response) => {
          new window.google.maps.Marker({
            position: this.startLocation,
            map: map,
            icon: {
              url: this.riderImage,
              scaledSize: new window.google.maps.Size(32, 32), // Scaled size of the icon
            },
          });

          // Add custom end marker
          new window.google.maps.Marker({
            position: this.endLocation,
            map: map,
            icon: {
              url: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png", // Custom end icon URL
              scaledSize: new window.google.maps.Size(32, 32), // Scaled size of the icon
            },
          });

          directionsRenderer.setDirections(response);
        })
        .catch((status) =>
          window.alert("Directions request failed due to " + status)
        );
    },
    async initMap() {
      if (this.showMapOrNot(this.orderData)) {
        var directionsService = new window.google.maps.DirectionsService();
        var directionsRenderer = new window.google.maps.DirectionsRenderer({
          suppressMarkers: true,
        });

        var icons = {
          start: new window.google.maps.MarkerImage(
            "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
            new window.google.maps.Size(44, 32),
            new window.google.maps.Point(0, 0),
            new window.google.maps.Point(22, 32)
          ),
          end: new window.google.maps.MarkerImage(
            "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
            new window.google.maps.Size(44, 32),
            new window.google.maps.Point(0, 0),
            new window.google.maps.Point(22, 32)
          ),
        };

        const { Map } = await window.google.maps.importLibrary("maps");
        let map = new Map(document.getElementById("map"), {
          center: { lat: -34.397, lng: 150.644 },
          zoom: 8,
          icons,
          markerOptions: {
            icon: {
              url: "https://path/to/your/custom/icon.png", // Custom icon URL
              scaledSize: new window.google.maps.Size(32, 32), // Scaled size of the icon
            },
          },
          gestureHandling: "greedy",
          mapTypeControl: false,
          fullscreenControl: false,
        });

        directionsRenderer.setMap(map);

        this.calculateAndDisplayRoute(
          directionsService,
          directionsRenderer,
          map
        );
      }
    },
    gotoRestaurantMenu() {
      this.$router.push(`/delivery/menu-list/${this.billDetails.restaurantId}`);
    },
    callDeliveryGuy() {
      window.open(
        `tel:${this.orderData.deliveryDetail?.partner_info.mobile.mobile_number}`
      );
    },
    callRestaurantGuy() {
      window.open(`tel:${this.restaurantDetails.contactNumber}`);
    },
    async getOrderDetails() {
      let orderId = this.$route.params.orderId;
      let result = await this.getApiCall("/delivery/getMyOrderDetail", {
        orderId,
      });
      this.orderData = result;
      this.orderList = result?.order || {};
      this.restaurantDetails = result?.restaurantInfo || {};
      this.billDetails = result?.billInfo || {};
      if (result?.billInfo && result.deliveryDetail) {
        this.partnerInfo = result.deliveryDetail?.partner_info;

        if (this.partnerInfo && result?.billInfo.deliveryData.drop_details) {
          this.startLocation = {
            lat: this.partnerInfo.location.lat,
            lng: this.partnerInfo.location.long,
          };
          this.center = {
            lat: result?.billInfo.deliveryData.drop_details.lat,
            lng: result?.billInfo.deliveryData.drop_details.lng,
          };

          if (this.orderData.deliveryDetail.status === "live") {
            this.endLocation = {
              lat: result?.billInfo.deliveryData.drop_details.lat,
              lng: result?.billInfo.deliveryData.drop_details.lng,
            };
            this.endImage = this.houseImage;
          } else if (this.orderData.deliveryDetail.status === "accepted") {
            this.endLocation = {
              lat: result?.billInfo.deliveryData.pickup_details.lat,
              lng: result?.billInfo.deliveryData.pickup_details.lng,
            };
            this.endImage = this.storeImage;
          }
          await this.initMap();
        }
      }

      // await this.initMap();
    },
    getDateTime(datetime) {
      return moment(datetime).format("LLL");
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-strip {
  p {
    color: green;
  }
}

.partner-panel {
  margin-top: 16px;
}

.add-top-padding {
  padding: 10px 0px 0px 0px;
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.container {
  margin: 16px 16px 0px 16px;

  .menu-icon {
    width: 40px;
  }

  .check-in-cards {
    background-color: #ffe6e6;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 5px;

    .check-in-card {
      .restaurant-name {
        // font-size: 20px;
      }

      .table-name {
        // font-size: 15px;
      }

      .otp {
        // font-size: 10px;

        .otp-value {
          // font-size: 30px;
        }
      }
    }
  }

  .menu-count {
    display: flex;
    justify-content: space-between;

    .count {
      margin-left: auto;
    }
  }

  .check-in-active {
    background-color: #ccffe6;
  }

  .map-container {
    margin-bottom: 16px;

    // .map-container-div {
    //   z-index: 1;
    //   position: relative;
    // }
  }
}

.restaurant-detail-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: auto;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 16px;
}

.order-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: #ead4a8;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 16px;

  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .image-container img {
    max-width: 30%;
    // max-height: 100%;
  }

  .status {
    text-align: center;
    // font-size: 18px;
    color: #333;
  }
}

.delivery-status {
  margin-bottom: 10px;
}

#map {
  width: 100%;
  height: 400px;
}

.page-footer {
  position: relative;
  // bottom: 16px;
  width: calc(100% - 32px);
  left: 16px;
}
</style>
