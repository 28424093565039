<template>
  <div>
    <top-navbar
      :topNavbarType="['back-button-target', 'current-address']"
      :topNavbarData="getCheckoutPageBackNavigateData()"
    ></top-navbar>
    <div class="bill-container">
      <div v-if="billDetails">
        <div>
          <div
            v-for="item in getOrders()"
            :key="item.kotNumber"
            class="kot-layout"
          >
            <div
              v-for="item1 in item.orderKotDetail"
              :key="item1.identifier"
              class="order-item-name"
            >
              <!-- <div>
                {{ item1 }}
              </div> -->
              <div class="display-flex">
                <p>{{ item1.itemName }}</p>
                <div class="display-flex">
                  <p>{{ `${item1.count}&nbsp; x &nbsp;` }}</p>
                  <div v-if="item1.discountPrice" class="display-flex-vertical">
                    <p>₹ {{ item1.discountPrice }}</p>
                    &nbsp;&nbsp; &nbsp;
                    <p>
                      <s>₹ {{ item1.itemPrice }}</s>
                    </p>
                  </div>
                  <div v-else class="display-flex-vertical">
                    <p>₹ {{ item1.itemPrice }}</p>
                  </div>
                </div>
              </div>
              <div
                v-for="item2 in item1.customizationDetail"
                :key="item2.identifier"
                class="item-customization"
              >
                <div class="display-flex">
                  <p>{{ item2.name }}</p>
                  <p v-if="item2.price && item2.price !== 'undefined'">
                    ₹ {{ item2.price }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!hideBillingSection">
          <div class="coupon-strip">
            <div class="coupon-strip-input">
              <input-field
                id="couponCode"
                :bindedClass="'search'"
                :onlyUpperCase="true"
                :img="'coupon.svg'"
                :value="couponCode"
                placeholder="Have a Coupon code ?"
                :style="{ border: '1px solid #D76E19' }"
                @change-handler="
                  ($event) => {
                    deliveryCouponCode = $event;
                  }
                "
              />
            </div>
            <div class="coupon-strip-button">
              <button-comp
                type="button"
                :height="'38px'"
                :value="'Apply'"
                :style="{ flex: 1, marginTop: '8px' }"
                @click="
                  () => {
                    checkCouponValidation(
                      deliveryCouponCode,
                      'DELIVERY_COUPON'
                    );
                  }
                "
              ></button-comp>
            </div>
          </div>
          <div
            v-if="billDetails.availableCupons"
            class="available-coupon-section"
          >
            <p>Available Coupons</p>
            <div class="coupon-card">
              <p>{{ billDetails.availableCupons.couponCode }}</p>
              <p
                @click="
                  () => {
                    couponCode = billDetails.availableCupons.couponCode;
                    checkCouponValidation(couponCode, 'FOOD_COUPON');
                  }
                "
              >
                Apply
              </p>
            </div>
          </div>
        </div>
        <div>
          <div v-if="addressText" :style="{ marginBottom: '16px' }">
            <p class="navbar-location-name solid">
              Delivery at {{ addressText.addressName }}
            </p>
            <p class="navbar-location-address">
              {{
                `${addressData.buildingAddress} ${addressData.streetAddress}`
                  .length > 32
                  ? `${addressData.buildingAddress} ${addressData.streetAddress}`.substring(
                      0,
                      27
                    ) + "..."
                  : `${addressData.buildingAddress} ${addressData.streetAddress}`
              }}
            </p>
          </div>
        </div>
        <div>
          <div :style="{ marginBottom: '16px' }">
            <div class="display-flex-no-space">
              <!-- <image-button
                :src="'/images/call.png'"
                :width="'14px'"
                alt=""
                :color="'white'"
                @click="callDeliveryGuy()"
              /> -->
              <!-- <p :style="{ marginLeft: '16px' }">Tapati +91 9874536456</p> -->
            </div>
          </div>
        </div>
        <div>
          <div
            v-if="this.billDetails.totalFoodBill && !hideBillingSection"
            class="bill-detail-container"
          >
            <div v-if="this.billDetails.couponDiscountAmount">
              <div class="display-flex discount-strip">
                <p class="bill-label">Coupon Code</p>
                <p>
                  {{ this.billDetails.couponCode }}
                </p>
              </div>
            </div>

            <div
              class="display-flex"
              @click="
                () => {
                  showBillDetail = true;
                }
              "
            >
              <p class="bill-label">Amount to Pay</p>
              <p>₹ {{ this.billDetails.totalBillToPay }}</p>
            </div>
          </div>
        </div>
        <div
          v-if="
            this.billDetails.totalBillToPay &&
            !hideBillingSection &&
            (!billDetails.paymentData ||
              (billDetails.paymentData &&
                billDetails.paymentData[0] &&
                (billDetails.paymentData[0].payment_status === 'FAILED' ||
                  billDetails.paymentData[0].payment_status ===
                    'USER_DROPPED')) ||
              billDetails.paymentData.length === 0)
          "
        >
          <ButtonComp
            :id="'bill-collect-button'"
            :value="`Pay ₹ ${this.billDetails.totalBillToPay}`"
            :height="'40px'"
            :width="'100%'"
            :color="'#F90C0C'"
            :textToShow="'Are you sure you want to place the order ?'"
            :showConfirmationModal="true"
            @clickEvent="paymentDone()"
          />
        </div>
      </div>
      <div class="cancellation-footer">
        <h3>Cancellation Policy</h3>
        <p>
          Help us reduce food waste by avoiding cancellations after placing your
          order. In case you want to cancel, 100% cancellation fees will be
          applied.
        </p>
      </div>
    </div>
    <BillDetailModal
      v-if="showBillDetail"
      :billDetails="billDetails"
      @turnCustomOrderModalOff="
        () => {
          showBillDetail = false;
        }
      "
    />
    <LoginModal v-if="showLoginModal" @turnOfLogin="turnOfLogin" />
  </div>
</template>
<script>
import { errorAlert } from "@/utility";
import BillDetailModal from "../modals/BillDetailModal.vue";
import LoginModal from "../Login/LoginModal.vue";
// eslint-disable-next-line
import { load } from "@cashfreepayments/cashfree-js";
// import { orderStore } from "../../store/order.store.js";
// import axios from "axios";

export default {
  components: { BillDetailModal, LoginModal },
  data() {
    return {
      billDetails: {},
      hideBillingSection: false,
      showBillDetail: false,
      showDiscountInput: false,
      discountPrice: 0,
      showFoodDiscountInput: false,
      foodDiscount: 0,
      showBarDiscountInput: false,
      barDiscount: 0,
      showHookahDiscountInput: false,
      hookahDiscount: 0,
      billExtraData: {},
      foodCouponCode: "",
      deliveryCouponCode: "",
      addressData: {},
      showLoginModal: false,
    };
  },
  async created() {
    let loginDetails = await localStorage.getItem("userData");

    if (loginDetails) {
      await this.getAddress();
    } else {
      this.showLoginModal = true;
    }
  },
  computed: {
    addressText() {
      return this.addressData;
    },
  },
  watch: {
    $route: {
      async handler() {
        let currentAddress = await localStorage.getItem("addressData");
        this.addressData = JSON.parse(currentAddress);
        this.getDeliveryBillDetails();
      },
      immediate: true,
    },
  },
  mounted() {
    document.addEventListener("deviceready", this.onDeviceReady, false);
  },
  methods: {
    turnOfLogin() {
      this.showLoginModal = false;
    },
    getCheckoutPageBackNavigateData() {
      return {
        title: "Menu",
        backTarget: `/delivery/menu-list/${this.billDetails?.restaurantId}`,
      };
    },
    onDeviceReady() {
      const self = this;
      const callbacks = {
        onVerify: function () {
          self.$router.push(`/bill-status/${self.billDetails._id}`);
        },
      };
      // eslint-disable-next-line no-undef
      window.CFPaymentGateway.setCallback(callbacks);
    },
    async paymentWorkflow() {
      // eslint-disable-next-line
      const cashfree = await Cashfree({
        mode: process.env.VUE_APP_CASHFREE_ENV, //or production
      });

      let getCashfreePaymentToken = await this.postApiCall(
        "/paymentGateway/paymentSessionId",
        { billId: this.billDetails._id }
      );

      if (window.cordova) {
        // eslint-disable-next-line no-undef
        window.CFPaymentGateway.doWebCheckoutPayment({
          theme: {
            navigationBarBackgroundColor: "#E64A19",
            navigationBarTextColor: "#FFFFFF",
          },
          session: {
            payment_session_id: getCashfreePaymentToken.sessionData,
            orderID: this.billDetails._id,
            environment:
              process.env.VUE_APP_CASHFREE_ENV === "production"
                ? "PRODUCTION"
                : "SANDBOX", //or production,
          },
        });
      } else {
        let redirectBaseUrl = window.location.origin;

        let checkoutOptions = {
          paymentSessionId: getCashfreePaymentToken.sessionData,
          returnUrl: `${redirectBaseUrl}/#/bill-status/${this.billDetails._id}`,
        };

        cashfree.checkout(checkoutOptions).then(function (result) {
          if (result.error) {
            alert(result.error.message);
          }
        });
      }
    },
    async paymentDone() {
      let restaurantDetail = await this.postApiCall(
        "/delivery/getRestaurantDetailsForCustomer",
        { restaurantId: this.billDetails.restaurantId }
      );
      if (restaurantDetail.status === "ONLINE") {
        await this.paymentWorkflow();
      } else {
        this.errorAlert("Sorry, the Restaurant is Offline");
      }
    },
    getOrders() {
      return this.billDetails.orderDetail;
    },
    turnModalOff() {
      this.showTableTranferModalBoolean = false;
      this.showTableMergeModalBoolean = false;
      this.showTransferKotModalBoolean = false;
    },
    async getDeliveryBillDetails() {
      let { orderId } = this.$route.params;
      let billExtraData = this.billExtraData;
      let addressData = this.addressData;
      if (addressData !== null) {
        const billDetails = await this.postApiCall(
          "/delivery/v2/getDeliveryBillDetails",
          { orderId, addressData, billExtraData }
        );

        if (billDetails === "COUPON_NOT_VERIFIED") {
          errorAlert("Sorry! Coupon not available.");
        } else {
          this.billDetails = billDetails;
        }

        if (
          !this.billDetails?.deliveryBill ||
          this.billDetails?.deliveryBill === 10000
        ) {
          this.hideBillingSection = true;
          this.errorAlert(
            "Delivery not available at the current drop location! Sorry for inconvenience!"
          );
        }
      } else {
        this.errorAlert("Please set the delivery location!");
      }
    },
    async checkCouponValidation(couponCode, couponCategory) {
      let { orderId } = this.$route.params;
      let couponStatus = await this.getApiCall("/delivery/verifyCoupon", {
        orderId,
        couponCode,
      });
      if (couponCategory === "FOOD_COUPON") {
        this.foodCouponCode = couponCode;
      }
      if (couponCategory === "DELIVERY_COUPON") {
        this.deliveryCouponCode = couponCode;
      }
      if (couponStatus === "COUPON_VERIFIED") {
        this.billExtraData = {
          ...this.billExtraData,
          foodCouponCode: this.foodCouponCode,
          deliveryCouponCode: this.deliveryCouponCode,
        };
        this.getDeliveryBillDetails();
      } else {
        this.billExtraData = {
          ...this.billExtraData,
          foodCouponCode: this.foodCouponCode,
          deliveryCouponCode: this.deliveryCouponCode,
        };
        await this.getDeliveryBillDetails();
        errorAlert("Sorry! Coupon not available.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.available-coupon-section {
  margin-bottom: 16px;

  .coupon-card {
    padding: 10px;
    background-color: aquamarine;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }
}

.cancellation-footer {
  margin-top: 16px;

  h3,
  p {
    color: grey;
  }
  h3 {
    font-size: 12px;
  }
  p {
    margin-top: 2px;
    font-size: 10px;
  }
}

.coupon-strip {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .coupon-strip-input {
    flex: 1;
    margin-right: 4px;
  }

  .coupon-strip-button {
    width: 25%;
  }
}

.categorised-bill-container {
  margin-bottom: 10px;
  border-radius: 5px;
}

.menu-icon {
  width: 40px;
}

.bill-label {
  font-weight: bolder;
  color: #d76e19;
}

.bill-detail-container {
  margin-bottom: 10px;

  .discount-strip {
    p {
      color: green;
    }
  }
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.display-flex-no-space {
  display: flex;
}

.display-flex-vertical {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
}

.kot-layout {
  .order-item-name {
    background-color: bisque;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 5px;

    .item-customization {
      color: #404040;
      // font-size: small;
      margin: 0px -16px 0px -16px;
      padding: 0px 16px;
    }
  }
}

.bill-container {
  margin: 16px 16px 0px 16px;

  .transfer-options {
    padding: 16px 16px 16px 16px;
    margin-bottom: 16px;
    border-radius: 5px;
    background-color: #f2f2f2;
  }

  .item-card {
    // background-color: #ee3f3f;
  }

  .order-list {
    padding: 10px;
    background-color: #e4e4e4;
    border-radius: 5px;
    margin-bottom: 2px;
  }
}
</style>
