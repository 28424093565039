<template>
  <div class="modal-container">
    <small-modal-component
      v-show="show"
      :isScrollable="true"
      :hideHeader="false"
    >
      <template v-slot:header>
        <p @click="turnOfLogin()">x</p>
      </template>
      <template v-slot:body>
        <div class="layout">
          <div class="welcome-text">
            <img
              style="width: 70%; border-radius: 5px"
              src="/images/login-icon.png"
              class="logo"
            />
            <h3>Hi! Welcome to</h3>
            <h1>Tumyto</h1>
          </div>
          <div v-if="sendotp">
            <div class="input-container-class">
              <h3>Login with</h3>
              <h2>Phone Number</h2>
              <input-field
                :id="'phoneNumber'"
                class="input-class"
                :height="'40px'"
                :type="'number'"
                :width="'100%'"
                :color="'#C59B31'"
                :placeholder="'Enter Phone Number'"
                @change-handler="
                  ($events) => {
                    this.phoneNumber = $events;
                  }
                "
              />
              <br />
              <button-comp
                :id="'getOtpButton'"
                :value="'Get OTP'"
                :height="'40px'"
                :width="'100%'"
                :color="'#C59B31'"
                @clickEvent="submitDetails"
              />
            </div>
          </div>
          <div v-if="verifyotp">
            <div>
              <h3>Please enter your</h3>
              <h2>OTP</h2>
              <input-field
                :id="'otp'"
                :type="'number'"
                :placeholder="'Enter OTP'"
                @change-handler="
                  ($events) => {
                    this.otp = $events;
                  }
                "
              />
              <br />
              <button-comp
                :id="'verifyOtpButton'"
                :value="'Verify OTP'"
                :height="'40px'"
                :type="'number'"
                :width="'100%'"
                :color="'#C59B31'"
                @clickEvent="verifyOTP"
              />
            </div>
          </div>

          <div v-if="setUsername">
            <div>
              <h2>Set Name</h2>
              <input-field
                :id="'username'"
                :placeholder="'Name'"
                @change-handler="
                  ($events) => {
                    this.username = $events;
                  }
                "
              />
              <input-field
                :id="'referralCode'"
                :placeholder="'Refer Code'"
                @change-handler="
                  ($events) => {
                    this.referralCode = $events;
                  }
                "
              />
              <br />
              <button-comp
                :id="'setUsernameButton'"
                :value="'Set Name'"
                :height="'40px'"
                :width="'100%'"
                :color="'#C59B31'"
                @clickEvent="setUsernameMethod"
              />
            </div>
          </div>
          <div>
            <p :style="{ marginTop: '20px' }">
              By continuing you agree to our
              <span :style="{ color: 'blue' }" @click="showPolicyList()"
                >Policies</span
              >
            </p>
          </div>
          <PolicyListModal
            v-if="policyListBoolean"
            @turnOffModal="
              () => {
                this.policyListBoolean = false;
              }
            "
          />
        </div>
      </template>
      <template v-slot:footer> </template>
    </small-modal-component>
  </div>
</template>

<script>
// import FirstOrderReviewModal from "../modals/FirstOrderReviewModal.vue";
import PolicyListModal from "../modals/PolicyListModal.vue";
import socket from "../../socket";
// eslint-disable-next-line no-unused-vars
import OneSignal from "onesignal-cordova-plugin";

export default {
  name: "LoginComponent",
  components: { PolicyListModal },
  data() {
    return {
      sendotp: true,
      verifyotp: false,
      phoneNumber: "",
      otp: "",
      referralCode: "",
      username: "",
      setUsername: false,
      policyListBoolean: false,
      show: true,
    };
  },
  async created() {
    // await axios.get('http://localhost:3000/api/user/loginWithPhoneNumber', {});
  },
  methods: {
    turnOfLogin() {
      console.log("G1");
      this.$emit("turnOfLogin");
    },
    showPolicyList() {
      this.policyListBoolean = true;
    },
    getHost() {
      return window.location.host;
    },
    async submitDetails() {
      const result = await this.postApiCall("/user/loginWithPhoneNumber", {
        phoneNumber: this.phoneNumber,
      });
      if (result === "OTP_SENT") {
        this.sendotp = false;
        this.verifyotp = true;
      } else if (result === "EXISTS") {
        this.sendotp = false;
        this.verifyotp = false;
      }
    },
    async verifyOTP() {
      const result = await this.postApiCall("/user/verifyOtp", {
        phoneNumber: this.phoneNumber,
        otp: this.otp,
      });
      if (result === "OTP_MISMATCH") {
        this.errorAlert("Wrong OTP entered");
      } else {
        let toStoreData = {
          authToken: result?.authToken,
          username: result?.username,
          userId: result?._id,
          accessRights: result?.accessRights,
        };
        try {
          await localStorage.setItem("userData", JSON.stringify(toStoreData));
        } catch (err) {
          console.log(err);
        }

        let userData = JSON.parse(await localStorage.getItem("userData"));
        let externalUserId = userData?.userId;
        try {
          window.plugins.OneSignal.login(externalUserId);
        } catch (e) {
          console.log("🚀 ~ OneSignalInit ~ error:", e);
        }
        if (!result.username) {
          this.setUsername = true;
          this.sendotp = false;
          this.verifyotp = false;
        } else {
          if (
            toStoreData?.accessRights?.includes("RECIEVE_ORDER") ||
            toStoreData?.accessRights?.includes("CHANGE_ORDER_STATUS")
          ) {
            socket.connect();
            this.$router.replace("/accept-delivery-order");
            this.infoAlert("Login Successfull");
          } else {
            // this.$router.replace("/home");
            // this.turnOfLogin();
            window.location.reload();
            this.infoAlert("Login Successfull");
          }
        }
      }
    },
    setUsernameMethod: async function () {
      let usernameResult = await this.postApiCall("/user/setUsername", {
        username: this.username,
        referralCode: this.referralCode,
      });
      if (usernameResult === "USERNAME_EXISTS") {
        this.errorAlert("Username Exists");
      } else {
        this.$router.replace("/home");
      }
    },
  },
};
</script>

<style lang="scss" scopped>
.layout {
  margin: 16px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 100px;
}

.welcome-text {
  margin-top: 40px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
