<template>
  <div class="sidebar-container">
    <div class="opaque-part"></div>
    <div class="sidebar-menu-partial">
      <div
        class="sidebar-menu-item"
        :style="{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '16px 0px 0px 16px',
        }"
        @click="closeSideBar"
      >
        <div>
          <img
            src="/images/profile-picture.png"
            :style="{ width: '50px' }"
            alt=""
          />
          <p :style="{ paddingLeft: '0px', fontSize: '15px' }">
            {{ getUserName() }}
          </p>
        </div>
        <p>Close</p>
      </div>
      <!-- <div class="sidebar-menu-item">
        <p>Account</p>
      </div> -->
      <div class="sidebar-menu-item" @click="goToPage('/home')">
        <p>Home</p>
      </div>
      <div
        v-if="hasAccessRight('SUPER_ADMIN')"
        class="sidebar-menu-item"
        @click="goToPage('/set-access-rights')"
      >
        <p>Super Admin</p>
      </div>
      <div
        v-if="hasAccessRight('VIEW_MY_ORDER')"
        class="sidebar-menu-item"
        @click="goToPage('/my-orders')"
      >
        <p>My Orders</p>
      </div>
      <div
        v-if="isSuperManager"
        class="sidebar-menu-item"
        @click="goToPage('/my-orders')"
      >
        <p>Change Restaurant Profile</p>
      </div>
      <div
        v-if="hasAccessRight('VIEW_DASHBOARD')"
        class="sidebar-menu-item"
        @click="goToPage('/dashboard-order-list')"
      >
        <p>Restaurant Dashboard</p>
      </div>
      <div
        v-if="hasAccessRight('RESTAURANT_ADMIN')"
        class="sidebar-menu-item"
        @click="goToPage('/dashboard-order-customization-edit')"
      >
        <p>Add Customization Categories</p>
      </div>
      <div
        v-if="hasAccessRight('RECIEVE_ORDER')"
        class="sidebar-menu-item"
        @click="goToPage('/dashboard-order-list')"
      >
        <p>Order Dashboard</p>
      </div>
      <div
        v-if="hasAccessRight('VIEW_GLOBAL_ORDER_LIST')"
        class="sidebar-menu-item"
        @click="goToPage('/get-global-orderlist')"
      >
        <p>Get Global Order List</p>
      </div>
      <div
        v-if="hasAccessRight('SUPER_ADMIN')"
        class="sidebar-menu-item"
        @click="goToPage('/create-default-filter-list')"
      >
        <p>Create Default Filter List</p>
      </div>
      <div
        v-if="hasAccessRight('RECIEVE_ORDER')"
        class="sidebar-menu-item"
        @click="goToPage('/dashboard-menu-list')"
      >
        <p>Edit Menu Item</p>
      </div>
      <div
        v-if="hasAccessRight('EDIT_RESTAURANT')"
        class="sidebar-menu-item"
        @click="goToPage('/add-restraunt')"
      >
        <p>Add Restaurant</p>
      </div>
      <div
        v-if="hasAccessRight('EDIT_RESTAURANT')"
        class="sidebar-menu-item"
        @click="goToPage('/edit-restraunt/null')"
      >
        <p>Edit Restaurant</p>
      </div>
      <div
        v-if="isChef"
        class="sidebar-menu-item"
        @click="goToPage('/kitchen-view-orders')"
      >
        <p>My Kitchen</p>
      </div>
      <div
        v-if="isManager"
        class="sidebar-menu-item"
        @click="goToPage('/add-table')"
      >
        <p>Add Table</p>
      </div>
      <div
        v-if="isCustomer"
        class="sidebar-menu-item"
        @click="goToPage('/my-check-ins')"
      >
        <p>My Check Ins</p>
      </div>
      <div
        v-if="
          hasAccessRight('RECIEVE_ORDER') ||
          hasAccessRight('CHANGE_ORDER_STATUS')
        "
        class="sidebar-menu-item"
        @click="goToPage('/accept-delivery-order')"
      >
        <p>Accept Orders</p>
      </div>
      <div
        v-if="isCaptain"
        class="sidebar-menu-item"
        @click="goToPage('/live-table-details')"
      >
        <p>Live Table Details</p>
      </div>
      <div
        v-if="isCaptain"
        class="sidebar-menu-item"
        @click="goToPage('/transfer-feature')"
      >
        <p>Transfer Entity</p>
      </div>
      <div
        v-if="isCaptain"
        class="sidebar-menu-item"
        @click="goToPage('/server-order-list')"
      >
        <p>Current Orders</p>
      </div>
      <div
        v-if="isCaptain && shouldShowTakeawayButton"
        class="sidebar-menu-item"
        @click="goToPage('/server-order-list-takeaway')"
      >
        <p>Orders for Takeaway</p>
      </div>
      <div
        v-if="isDriver && showGoOnlineButton"
        class="sidebar-menu-item"
        @click="goToPage('/server-order-list-takeaway')"
      >
        <p>Go Online</p>
      </div>
      <div class="sidebar-menu-item" @click="setPage('/policies/aboutus.txt')">
        <p>About Us</p>
      </div>
      <div
        class="sidebar-menu-item"
        @click="setPage('/policies/contactus.txt')"
      >
        <p>Contact Us</p>
      </div>
      <!-- <div
        class="sidebar-menu-item"
        @click="setPage('/policies/termsnconditions.txt')"
      >
        <p>Terms & Conditions</p>
      </div> -->
      <!-- <div
        class="sidebar-menu-item"
        @click="setPage('/policies/cancellation.txt')"
      >
        <p>Cancellation Policy</p>
      </div> -->
      <!-- <div class="sidebar-menu-item" @click="setPage('/policies/refund.txt')">
        <p>Refund Policy</p>
      </div> -->
      <!-- <div
        class="sidebar-menu-item"
        @click="setPage('/policies/shipingpolicy.txt')"
      >
        <p>Shipment Policy</p>
      </div> -->
      <!-- <div class="sidebar-menu-item" @click="setPage('/policies/privacy.txt')">
        <p>Privacy Policy</p>
      </div> -->
      <div class="sidebar-menu-item" @click="logout()">
        <p>Logout</p>
      </div>
    </div>
    <div class="sidebar-footer">
      <p :style="{ color: 'white' }">TUMYTO by MS BRAHMAS SOLUTIONS</p>
    </div>
  </div>
</template>

<script>
import utilityMixin from "../mixin/utilityMixin";
// eslint-disable-next-line no-unused-vars
import OneSignal from "onesignal-cordova-plugin";

export default {
  name: "SideBar",
  mixins: [utilityMixin],
  data() {
    return {
      counter: 0,
      isCaptain: false,
      isManager: false,
      isChef: false,
      isDriver: false,
      isCustomer: false,
      showAddRestaurant: false,
      isSuperManager: false,
      shouldShowTakeawayButton: false,
      showGoOnlineButton: false,
    };
  },
  computed: {},
  watch: {
    $route: {
      handler() {},
    },
  },
  created() {},
  mounted() {},
  methods: {
    setPage(pageName) {
      window.location = `${pageName}`;
    },
    closeSideBar() {
      this.$store.dispatch("setStatusBar", false);
    },
    goToPage(routeTo) {
      this.closeSideBar();
      this.$router.push(routeTo);
    },
    async logout() {
      try {
        window.plugins.OneSignal.logout();
      } catch (e) {
        console.log(e);
      }
      this.closeSideBar();
      await localStorage.removeItem("addressData");
      await localStorage.removeItem("userData");
      this.$router.replace("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-footer {
  position: absolute;
  padding: 16px;
  background-color: #da6d07;
  bottom: 0px;
  z-index: 5000;
  width: 80%;
  // flex: 1;

  p {
    text-wrap: wrap;
  }
}
.sidebar-container {
  z-index: 1010;
  // display: flex;

  .opaque-part {
    background-color: #000000;
    opacity: 0.5;
    width: 100%;
    // flex: 1;
    height: 100%;
    position: fixed;
    z-index: 1010;
  }

  .sidebar-menu-partial {
    background-color: #ffffff;
    position: fixed;
    opacity: 1;
    flex: 1;
    width: 80%;
    height: 100%;
    z-index: 1011;
    padding-bottom: 200px;
    // overflow-y: scroll;

    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    .sidebar-menu-item {
      p {
        padding: 16px 16px 16px 16px;
      }
    }
  }
}
</style>
